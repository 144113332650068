<template>
  <div class="product">        
    <!-- 产品新增 -->    
      <van-nav-bar
        title="产品新增"
        left-text="返回"    
        left-arrow   
        @click-left="toHome"  
      /> 
      <van-form style="">        
        <van-cell-group inset>                     
          <van-field
            v-model="erpCode"
            name="ERP编码"
            label="ERP编码"
            placeholder="请输入ERP编码"
            input-align=""  
            clearable   
            type="number"       
          />          
          <!-- <van-field
            required
            v-model="sampleName"    
            name="产品名称"
            label="产品名称"
            placeholder="请输入产品名称"
            input-align=""
            clearable
          />  -->        
          <van-search  class="sample" required v-model="sampleName" label="产品名称" placeholder="请输入产品关键词" @input="searchSample"/>
            <van-action-sheet v-model="sampleShow" :actions="sampleOption" @select="sampleSelect"  :overlay="false" style="height: 40%" />
          <van-field
            v-model="sampleSpecs"    
            name="产品规格"
            label="产品规格"
            placeholder="请输入产品规格"
            input-align=""
            clearable
          /> 
          <van-field         
            v-model="sampleJx"
            is-link
            readonly
            label="产品剂型"
            placeholder="选择产品剂型"
            @click="getJx"
            input-align="left"
          />
          <van-field style="display: none;"        
            v-model="supplier"
            is-link
            readonly
            label="供应商"
            placeholder="选择供应商"
            @click="getSupplier"
            input-align="left"
          />
        </van-cell-group>
        <van-popup v-model="showhgPicker" round position="bottom">
          <van-picker
            show-toolbar
            title="选择产品剂型"
            :columns="jxList"
            value-key="jx_name"
            @cancel="showhgPicker = false"
            @confirm="jxConfirm"        
          />
      </van-popup>
      <van-action-sheet v-model="upShow" title="供应商" class="elasticlayer" :overlay="false" style="height: 55%">
        <van-search v-model="supplierValue" placeholder="请输入搜索关键词" @input="searchSupplier" />
        <van-action-sheet v-model="upShow" :actions="option1" @select="onSelect"  :overlay="false" style="height: 40%" />
      </van-action-sheet>
        <div style="margin: 16px;">
          <van-button class="" round block size="small" type="primary"  @click="preservAdd" style="margin-top: 100px;" v-track="{triggerType:'click', title:'新增产品',currentUrl: $route.path,behavior: this._data, duration: new Date().getTime()}">保 存</van-button> 
        </div>
      </van-form>              
  </div>
</template>
<script>
import Vue from 'vue';
import { List, Cell, CellGroup, Tag, Col, Row, Button, Popup, Field, Toast, Icon, ActionSheet, Form, Picker,NavBar,Search  } from 'vant';
import { parse } from 'qs';
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(ActionSheet);
Vue.use(Form);
Vue.use(Picker);
Vue.use(NavBar);
Vue.use(Search);
export default {  
  name: 'ProductAdd',
  components: {},
  
  data() {    
    return { 
      password: '',
      username: '',  
      showhgPicker: false, 
      sampleShow: false,        
      erpCode: '', //erp编码
      sampleName: '', //产品名称
      sampleSpecs: '', //产品规格 
      sampleId: '', //剂型id 
      sampleJx: '',  //剂型
      supplier: '', //供应商
      supplierCode: '',
      supplierValue: '',
      sampleOption: [], //产品列表      
      option1: [], //下拉列表
      jxList: [],      
      upShow: false,
    };
  },
  created: function () {
     
  },
  mounted() {    
  },
  methods: {              
    onSubmit() {

    },       
    // 新增产品
    addOne() {
      this.erpCode = ''    
      this.sampleName = ''
      this.sampleSpecs = '' 
    },
    // 新增保存
    preservAdd() {      
      var that = this    
      var params = {
        sample_name: this.sampleName,
        sample_specs: this.sampleSpecs,
        sample_erp_code: this.erpCode,
        sample_type: 2,
        sample_jx: this.sampleId,
        supplier_code: this.supplierCode
      } 
      this.$post('sample/add-sample', 'api', params).then(res => {      
        this.$notify({ type: 'success', message: res.message })
        this.$router.push('/productList')    
      }, err => {
        console.log(err)      
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 获取剂型列表
    getJx() {
      this.showhgPicker = true
      var params = {
        page: 1,
        offset: 100,
        jx_name: ''
      }
      this.$get('sample-jx/get-sample-jx-list', 'api', params).then(res => {      
        var data = res.data.list
        this.jxList = data
      }, err => {
        console.log(err)      
        
      })
    },
    // 选择剂型
    jxConfirm (value) {
      this.sampleJx = value.jx_name
      this.sampleId = value.id
      this.showhgPicker = false
    },
    // 搜索产品
    searchSample(value) { 
      if(value == '') {
        this.sampleShow = false
      } else {
        var params = {
          page: 1,
          offset: 100,
          sample_name: value
        }
        this.$get('sample/get-erp-material-list', 'api', params).then(res => { 
          this.sampleShow = true    
          var data = res.data.list
          data.forEach(ele => {
            ele.name = ele.sample_name+'['+ ele.sample_code+']'        
          });                             
          this.sampleOption = data
        }, err => {
          // console.log(err)  
          this.sampleShow = false      
        })
      }           
    },
    // 选择产品
    sampleSelect(item) {        
      this.erpCode = item.sample_code    
      this.sampleName = item.sample_name
      this.sampleSpecs = item.sample_specs
      this.sampleShow = false
    },
    // 选择供应商
    getSupplier() {
      this.searchSupplier('')
    },
    // 搜索供应商
    searchSupplier(value) {
      this.upShow = true
      var params = {
        page: 1,
        offset: 100,
        supplier_name: value
      }
      this.$get('supplier/get-supplier-list', 'api', params).then(res => {     
        var data = res.data.list
        data.forEach(ele => {
          ele.name = ele.supplier_name         
        });                             
        this.option1 = data
      }, err => {
        // console.log(err)        
      })
    },
    // 选择
    onSelect(item) {
      this.supplierCode = item.supplier_code
      this.supplier = item.supplier_name
      this.upShow = false
    },
    // 返回
    toHome() {
      this.$router.push({
        path: '/home'     
      })
    }                          
  }          
};
</script>
<style scoped>
 .tac {
   text-align: center;
 } 
 .sample >>> .van-search__content {
   padding-left: 0 !important;
 }
 .sample >>> .van-search__label {
   margin-right: 17px !important;
 }
 .sample >>> .van-cell--required::before {
   left: -84px !important;
 }
</style>